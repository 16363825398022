import { css } from "@emotion/core"

import { mq } from "./media-query"

import a1 from "../../images/blog/1.jpeg"
import a2 from "../../images/blog/2.jpeg"
import a3 from "../../images/blog/3.webp"
import a4 from "../../images/blog/4.webp"
import a5 from "../../images/blog/5.webp"
import a6 from "../../images/blog/6.webp"
import a7 from "../../images/blog/7.webp"
import a8 from "../../images/blog/8.webp"
import a9 from "../../images/blog/9.webp"

const imgStyle = {
  width: "100%",
  height: "300px",
  margin: "0 auto 1rem auto",
  textAlign: "cover",
  "& img": {
    objectFit: "contain",
    // minHeight: "90%",
    width: "80%",
    height: "90%",

    [mq[1]]: {
      maxWidth: "90%",
    },
  },
}
const extraImgStyle = {
  paddingTop: "1.5rem",
}

const captionStyle = {
  textTransform: "capitalize",
  fontWeight: "700",
  color: "var(--clr-primary-300)",
  fontSize: "1.1rem",
  letterSpacing: "var(--letter-spacing-sm)",
  width: "80%",
  marginLeft: "auto",
  marginRight: "auto",
  height: "52px",
}

const cardStyle = css`
  background: none;
  border-radius: var(--border-radius-md);
  text-align: center;
  height: 100%;
  box-shadow: var(--dark-shadow-sb);
  margin: 0 auto;
  width: 90%;
  padding: 1.5rem;
  align-self: flex-start;
  min-height: 300px;
  /* display: grid; */
  ${mq[1]} {
    padding-bottom: 0;
  }
`
const textStyle = css`
  text-align: left;
  padding: 0 1rem 1.5rem;
  color: var(--clr-neutral-400);
  font-size: 0.825rem;
  letter-spacing: var(--letter-spacing-sm);
`

const hoverEffect = css`
  &:hover {
    box-shadow: none;
    border: 2px solid var(--clr-primary-300);
    cursor: pointer;
  }
`

export default [
  {
    image: {
      src: a1,
      alt: "avoid-pitfalls",
    },
    label: "avoid pitfalls",
    caption: "旅游保险避坑心得",
    imgStyle: { ...imgStyle, ...extraImgStyle },
    cardStyle,
    captionStyle,
    text:
      "计划出国转转的小伙伴们，准备出国读书的学子们，想要去探亲的爸爸妈妈爷爷奶奶们，别忘了买上一份旅游保险。",
    textStyle,
    hoverEffect,
    onClick: () =>
      typeof window !== undefined &&
      window.open(
        "https://mp.weixin.qq.com/s?__biz=MzI2MjAxMzE0Mw==&amp;mid=2247483768&amp;idx=1&amp;sn=7a0ff9c9acda5fe13f937cac5d467612&amp;chksm=ea50e8c0dd2761d6efc67aba800caa1360adaf8d42385bac1e3028e1dac872238e0ff22ba48c&token=252845842&lang=zh_CN#wechat_redirect",
        "_blank"
      ),
    date: "2019-04-04",
  },
  {
    image: {
      src: a2,
      alt: "how-to-choose",
    },
    label: "how-to-choose",
    caption: "出境游 - 你还在瞎买旅游保险吗？",
    imgStyle: { ...imgStyle, ...extraImgStyle },
    cardStyle,
    captionStyle,
    text:
      "更加有针对性的深入解读旅游保险的保障范围和保费的计算，希望可以帮助大家找到最适合你的最划算的旅游保险产品。",
    textStyle,
    hoverEffect,
    onClick: () =>
      typeof window !== undefined &&
      window.open(
        "https://mp.weixin.qq.com/s?__biz=MzI2MjAxMzE0Mw==&amp;mid=2247483917&amp;idx=1&amp;sn=920beb83a2f7a7505796e1d3da3305d2&amp;chksm=ea50ebb5dd2762a332de6ac52ef32ad209483637fdadc0bc79f8870d92aa1df09f54d0dd040b&token=252845842&lang=zh_CN#wechat_redirect",
        "_blank"
      ),
    date: "2019-04-27",
  },
  {
    image: {
      src: a3,
      alt: "rocky-mt-1",
    },
    label: "rocky-mt-1",
    caption: "带你转转落基山的犄角旮旯儿（一）",
    imgStyle: { ...imgStyle, ...extraImgStyle },
    cardStyle,
    captionStyle,
    text:
      "今天带大家转的是犄角旮旯儿里的下午茶 —— Mount Engadine Lodge 山林小屋",
    textStyle,
    hoverEffect,
    onClick: () =>
      typeof window !== undefined &&
      window.open(
        "https://mp.weixin.qq.com/s?__biz=MzI2MjAxMzE0Mw==&amp;mid=2247483766&amp;idx=1&amp;sn=e7db9554806cb4d235d6bb69b8cdab02&amp;chksm=ea50e8cedd2761d85aee8a99f2747876786a751b654cb794a6b70178039aee29b0fbd8beb53d&token=252845842&lang=zh_CN#wechat_redirect",
        "_blank"
      ),
    date: "2019-04-03",
  },
  {
    image: {
      src: a4,
      alt: "Azuridge Estate Hotel",
    },
    label: "Azuridge Estate Hotel",
    caption: "Azuridge Estate Hotel - 落基山脚下的低调奢华",
    imgStyle: { ...imgStyle, ...extraImgStyle },
    cardStyle,
    captionStyle,
    text: "作为开号首发，鼹鼠君准备拿出压箱底儿的宝贝给大家晒一晒。",
    textStyle,
    hoverEffect,
    onClick: () =>
      typeof window !== undefined &&
      window.open(
        "https://mp.weixin.qq.com/s?__biz=MzI2MjAxMzE0Mw==&amp;mid=2247483707&amp;idx=1&amp;sn=0e6fae7d03f6d4a6b49fe50d89dfb7a4&amp;chksm=ea50e883dd2761958e46daa6057017cfd63b186d2b173f7e2f379107198d667bafd0bbf64d72&token=252845842&lang=zh_CN#wechat_redirect",
        "_blank"
      ),
    date: "2019-03-27",
  },
  {
    image: {
      src: a5,
      alt: "rocky-mt-2",
    },
    label: "rocky-mt-2",
    caption: "带你转转落基山的犄角旮旯儿（二）",
    imgStyle: { ...imgStyle, ...extraImgStyle },
    cardStyle,
    captionStyle,
    text:
      "今天带大家转的这个地方在大名鼎鼎的纽约时报（New York Times)上公布的2019年必去的52个地方的名单上位列20！",
    textStyle,
    hoverEffect,
    onClick: () =>
      typeof window !== undefined &&
      window.open(
        "https://mp.weixin.qq.com/s?__biz=MzI2MjAxMzE0Mw==&amp;mid=2247483813&amp;idx=1&amp;sn=78b1dfc9ac6f1e149398074c95431238&amp;chksm=ea50e81ddd27610b4e4829a3d37098ef2783066ddf55801fc2a9dacb8155294b39f5187111e4&token=252845842&lang=zh_CN#wechat_redirect",
        "_blank"
      ),
    date: "2019-04-06",
  },
  {
    image: {
      src: a6,
      alt: "calgary-1",
    },
    label: "calgary-1",
    caption: "卡屯的暴力美学（一）",
    imgStyle: { ...imgStyle, ...extraImgStyle },
    cardStyle,
    captionStyle,
    text: "看图说话",
    textStyle,
    hoverEffect,
    onClick: () =>
      typeof window !== undefined &&
      window.open(
        "https://mp.weixin.qq.com/s?__biz=MzI2MjAxMzE0Mw==&amp;mid=2247483875&amp;idx=1&amp;sn=1d5dd11359c3e9181cb5cf6d6bcb4505&amp;chksm=ea50e85bdd27614d1106d059d9442491cd90b197d1c3645d16c25515e52e762a9d26f5295b6a&token=252845842&lang=zh_CN#wechat_redirect",
        "_blank"
      ),
    date: "2019-04-14",
  },
  {
    image: {
      src: a7,
      alt: "once-in-a-lifetime",
    },
    label: "once-in-a-lifetime",
    caption: "一生也许只有一次的机会 - 你不来吗？",
    imgStyle: { ...imgStyle, ...extraImgStyle },
    cardStyle,
    captionStyle,
    text: "什么是一生也许只有一次的机会？",
    textStyle,
    hoverEffect,
    onClick: () =>
      typeof window !== undefined &&
      window.open(
        "https://mp.weixin.qq.com/s?__biz=MzI2MjAxMzE0Mw==&amp;mid=2247483899&amp;idx=1&amp;sn=c684fd61ea9655a02b27fba2dd4536f9&amp;chksm=ea50e843dd276155496b12c68e336922adb077d3fb8371bf7f536f405251f9a3019d56c976fc&token=252845842&lang=zh_CN#wechat_redirect",
        "_blank"
      ),
    date: "2019-04-19",
  },
  {
    image: {
      src: a8,
      alt: "why-immigrate",
    },
    label: "why-immigrate",
    caption: "移民到底为了什么：从财富500强到卖旅游保险",
    imgStyle: { ...imgStyle, ...extraImgStyle },
    cardStyle,
    captionStyle,
    text:
      "大多数人移民都是为了孩子，我移民是从一个不经意的玩笑开始，陪一个姐们儿去中介办移民，根本对移民想都没想过，结果被中介看上，经过6年糊里糊涂拿到签证。",
    textStyle,
    hoverEffect,
    onClick: () =>
      typeof window !== undefined &&
      window.open(
        "https://mp.weixin.qq.com/s?__biz=MzI2MjAxMzE0Mw==&amp;mid=2247483923&amp;idx=1&amp;sn=d4ffba4ea492195258cbefe2e11fb2d7&amp;chksm=ea50ebabdd2762bd229a80912331898829c05fe304a0e5e9a861d745a649acad05b0c09068f1&token=252845842&lang=zh_CN#wechat_redirect",
        "_blank"
      ),
    date: "2019-04-28",
  },
  {
    image: {
      src: a9,
      alt: "night-with-pianist",
    },
    label: "night-with-pianist",
    caption: "和疯狂钢琴师共度良宵",
    imgStyle: { ...imgStyle, ...extraImgStyle },
    cardStyle,
    captionStyle,
    text:
      "听到钢琴师的时候你脑海里出现的形象是不是身着晚礼服，在台上演奏高雅的古典音乐，底下观众鸦雀无声的那种？",
    textStyle,
    hoverEffect,
    onClick: () =>
      typeof window !== undefined &&
      window.open(
        "https://mp.weixin.qq.com/s?__biz=MzI2MjAxMzE0Mw==&amp;mid=2247484010&amp;idx=1&amp;sn=a5f122a863b6cb59a62c34e9a73233d5&amp;chksm=ea50ebd2dd2762c444dd9aff95fc77d7607e5da06faa1a3312a66f7814aff101ae00446a93c1&token=252845842&lang=zh_CN#wechat_redirect",
        "_blank"
      ),
    date: "2019-05-25",
  },
].sort((a, b) => {
  if (a.date > b.date) return -1
  else if (a.date < b.date) return 1
  return 0
})

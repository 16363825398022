import React, { useEffect } from "react"
import styled from "@emotion/styled"
import { connect, useDispatch } from "react-redux"
import { navigate } from "gatsby"
import { css } from "@emotion/core"
import { useIntl } from "react-intl"

import {
  Layout,
  Title,
  SectionCenter,
  PrimaryButton,
  Card,
} from "../components"
import blogCards from "../utils/blogCards"
import { getCurrentPath } from "../redux/actions"

const Blog = ({ location, getCurrentPath }) => {
  const intl = useIntl()
  useEffect(() => {
    getCurrentPath(location.pathname)
  })
  return (
    <Layout>
      <section
        css={css`
          padding: 5rem 0;
        `}
      >
        <Title
          title={intl.formatMessage({ id: "blog.title" })}
          fontColor="var(--clr-primary-300)"
        />
        <SectionCenter>
          <div
            css={css`
              max-width: 600px;
              margin: 0 auto 1rem;
              display: grid;
              row-gap: 3rem;
              grid-template-columns: 1fr;
              justify-content: center;

              @media (min-width: 947px) {
                max-width: 1170px;
                width: 100%;
                grid-template-columns: 45% 45%;
                justify-items: center;
                align-items: center;
                column-gap: 1rem;
              }
            `}
          >
            {blogCards.map(cardItem => (
              <Card key={cardItem.label} {...cardItem} />
            ))}
          </div>
          <div
            css={css`
              display: flex;
              justify-content: center;
            `}
          >
            <PrimaryButton
              textSize="0.875rem"
              textTransform="capitalize"
              maxWidth="none"
              margin="3rem 0 0 0"
              onClick={() => {
                navigate("/")
              }}
            >
              {intl.formatMessage({ id: "btn.return" })}
            </PrimaryButton>
          </div>
        </SectionCenter>
      </section>
    </Layout>
  )
}

export default connect(null, { getCurrentPath })(Blog)
